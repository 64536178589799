require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/34bc5a9dad2e70c1ea2169452bd21508/code.js', () => { 

BackendlessUI.Functions.Custom['fn_34bc5a9dad2e70c1ea2169452bd21508'] = function fn_34bc5a9dad2e70c1ea2169452bd21508(month_num) {
return (['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][((month_num - 1) - 1)])
}

})
define('./functions/d58aea1a4fdc960e8bfcc8c9972ad006/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d58aea1a4fdc960e8bfcc8c9972ad006'] = function fn_d58aea1a4fdc960e8bfcc8c9972ad006(succes_url, cancel_url, item_list) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ([String('success_url=') + String(succes_url),'&',String('cancel_url=') + String(cancel_url),'&',String('mode=') + String('payment'),'&',String('customer_creation=') + String('always'),'&',String('phone_number_collection[enabled]=') + String('true'),'&',String('billing_address_collection=') + String('required'),['&','line_items[0][price_data][currency]=','USD','&','line_items[0][price_data][product_data][name]=',(getObjectProperty((item_list[0]), 'name')),'&','line_items[0][price_data][unit_amount]=',(getObjectProperty((item_list[0]), 'price')),'&','line_items[0][quantity]=',(getObjectProperty((item_list[0]), 'quant'))].join(''),['&','line_items[1][price_data][currency]=','USD','&','line_items[1][price_data][product_data][name]=',(getObjectProperty((item_list[1]), 'name')),'&','line_items[1][price_data][unit_amount]=',(getObjectProperty((item_list[1]), 'price')),'&','line_items[1][quantity]=',(getObjectProperty((item_list[1]), 'quant'))].join(''),3 == item_list.length ? ['&','line_items[2][price_data][currency]=','USD','&','line_items[2][price_data][product_data][name]=',(getObjectProperty((item_list[2]), 'name')),'&','line_items[2][price_data][unit_amount]=',(getObjectProperty((item_list[2]), 'price')),'&','line_items[2][quantity]=',(getObjectProperty((item_list[2]), 'quant'))].join('') : ''].join(''))
}

})
define('./functions/efab0d1eefce8e68bee14fa523a21807/code.js', () => { 

BackendlessUI.Functions.Custom['fn_efab0d1eefce8e68bee14fa523a21807'] = function fn_efab0d1eefce8e68bee14fa523a21807(weekday_num) {
return (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][((weekday_num - 1) - 1)])
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/box-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/box-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')),[`lic_plate_num`]: (getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 20 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/box-options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/box-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/cancel/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var site_registration, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  site_registration['reg_end'] = (new Date());
  try {
    await Backendless.Data.of('site_reg').save(site_registration, true);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/cancel/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/cashier-confirm/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var site_registration, new_reg_end, reg_start, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  reg_start = (JSON.parse((getObjectProperty(site_registration, 'reg_start'))));
  new_reg_end = (new Date((new Date(reg_start)).getTime() + (((Number((getObjectProperty(site_registration, 'num_nights')))) * 86400) * 1000)));
  new_reg_end.setHours(14);
  new_reg_end.setMinutes(0);
  new_reg_end.setSeconds(0);
  site_registration['reg_end'] = new_reg_end;
  ___arguments.context.pageData['site_reg'] = site_registration;
  try {
    await Backendless.Data.of('site_reg').save(site_registration, true);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/cashier-confirm/components/0465abf544ddda81122fd7ba4dca77f3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Your site is set through ') + String(new Intl.DateTimeFormat('us', ({ [`dateStyle`]: 'full',[`timeStyle`]: 'medium',[`propName`]: 'true' })).format((getObjectProperty(___arguments.context.pageData, 'site_reg.reg_end')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/cashier-confirm/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/cashier-log-on/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var available_sites, item;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/cashier-log-on/components/83e1e6c7b3aef8e3827ddf0d31e63f8c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((await Backendless.UserService.login( (getObjectProperty(___arguments.context.pageData, 'username')), (getObjectProperty(___arguments.context.pageData, 'password')), true  )));
  console.log(Backendless.getCurrentUserToken());
  if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('select-site-cashier', undefined);
  } else {
    (function (message) { alert(message) })('We are unable to log you in.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/cashier-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/cashier-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var site_registration;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 20 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/cashier-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/coming-soon/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/coming-soon/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var error, site_registration, new_reg_end, reg_start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Counters.addAndGet('coming_soon', 1);
  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  reg_start = (JSON.parse((getObjectProperty(site_registration, 'reg_start'))));
  new_reg_end = (new Date((new Date(reg_start)).getTime() + (((Number((getObjectProperty(site_registration, 'num_nights')))) * 86400) * 1000)));
  new_reg_end.setHours(14);
  new_reg_end.setMinutes(0);
  new_reg_end.setSeconds(0);
  site_registration['reg_end'] = new_reg_end;
  ___arguments.context.pageData['site_reg'] = site_registration;
  try {
    await Backendless.Data.of('site_reg').save(site_registration, true);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/czech-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-permit';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/czech-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('czech-payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')),[`lic_plate_num`]: (getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 18 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/czech-options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/czech-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/czech-payment/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var list, service_objs, pass_included;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  service_objs = ({ [`site`]: ({ [`servName`]: 'Czechland Electric Site',[`price`]: 1800 }),[`daily_permit`]: ({ [`servName`]: 'Lower Platte North NRD Daily Permit',[`price`]: 500 }),[`annual_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Resident Permit',[`price`]: 1500 }),[`annual_non_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Non-Resident Permit',[`price`]: 2500 }) });
  pass_included = (getObjectProperty(___arguments.context.pageData, 'pass_type')) == 'nopass' ? false : true;
  list = [];
  addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'site.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'site.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'daily_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'daily_permit.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_res_permit.price')))),[`quant`]: 1 }));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_non_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_non_res_permit.price')))),[`quant`]: 1 }));
  } else if ('nopermit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
  }
  addItemToList(list, ({ [`name`]: 'Set Your Sites Fee',[`price`]: Math.min(Math.max(200 * (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))), 100), 800),[`quant`]: 1 }));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/checkout/checkout`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'checkoutItems': list,'reg_id': (getObjectProperty(___arguments.context.pageData, 'reg_id')) })), false, undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/czech-site-status/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var registration, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    registration = (await Backendless.Request.post(`${Backendless.appPath}/services/regs/new`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'siteObjectId')))));

  } catch (error) {
    console.log(error);

  }
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('czech-options', ({ [`reg_id`]: (getObjectProperty(registration, 'objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/czech-site-status/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var loaded_site;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  loaded_site = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/check`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pid': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'physical_id'))) }).send());
  if ((getObjectProperty(loaded_site, 'site')) == 'not-found') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('coming-soon', undefined);
  } else {
    ___arguments.context.pageData['site_name'] = (getObjectProperty(loaded_site, 'site.site.site_name'));
    ___arguments.context.pageData['site_avail'] = (getObjectProperty(loaded_site, 'site.available'));
    ___arguments.context.pageData['org_unit_name'] = (getObjectProperty(((getObjectProperty((getObjectProperty((getObjectProperty(loaded_site, 'site')), 'site')), 'org_unit'))[0]), 'org_unit_name'));
    ___arguments.context.pageData['siteObjectId'] = (getObjectProperty(loaded_site, 'site.site.objectId'));
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/czech-site-status/components/b805671e4a1e37767e4841bcce228abf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'site_avail')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/czech-site-status/components/101133bd3c65dcc1199f854b5bf6716e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'site_avail')) ? 'This site is available!' : 'This site is not currently available.')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/czech-site-status/components/401aeab94dd152d9becc08ec23f29858/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/czech-site-status/components/1f941ae64af645ac3fe266c5d84ed395/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'org_unit_name')),' - ',(getObjectProperty(___arguments.context.pageData, 'site_name'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')),[`lic_plate_num`]: (getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 20 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/payment/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var checkout_obj, list, service_objs, pass_included;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  service_objs = ({ [`site`]: ({ [`servName`]: 'Wanahoo Electric Site',[`price`]: 2000 }),[`daily_permit`]: ({ [`servName`]: 'Lower Platte North NRD Daily Permit',[`price`]: 500 }),[`annual_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Resident Permit',[`price`]: 1500 }),[`annual_non_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Non-Resident Permit',[`price`]: 2500 }) });
  pass_included = (getObjectProperty(___arguments.context.pageData, 'pass_type')) == 'nopass' ? false : true;
  list = [];
  addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'site.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'site.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'daily_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'daily_permit.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_res_permit.price')))),[`quant`]: 1 }));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_non_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_non_res_permit.price')))),[`quant`]: 1 }));
  } else if ('nopermit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
  }
  addItemToList(list, ({ [`name`]: 'Set Your Sites Fee',[`price`]: Math.min(Math.max(200 * (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))), 100), 800),[`quant`]: 1 }));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/checkout/checkout`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'checkoutItems': list,'reg_id': (getObjectProperty(___arguments.context.pageData, 'reg_id')) })), false, undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/permit-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var error, registration;


  try {
    registration = (await Backendless.Request.post(`${Backendless.appPath}/services/regs/new`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify('CBBE4683-BC6D-4F1E-8AE1-5FC8B5217D22')));
    ___arguments.context.pageData['registration'] = registration;

  } catch (error) {
    console.log(error);

  }
  ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/permit-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (getObjectProperty(___arguments.context.pageData, 'registration'));
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    site_registration['reg_start'] = (new Date());
    console.log(site_registration);
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('permit-payment', ({ [`num_nights`]: (getObjectProperty(site_registration, 'num_nights')),[`i_agree`]: (getObjectProperty(site_registration, 'i_agree')),[`permit_type`]: (getObjectProperty(site_registration, 'permit_type')),[`reg_id`]: (getObjectProperty(site_registration, 'objectId')),[`lic_plate_num`]: (getObjectProperty(site_registration, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 1 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(0 + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/permit-options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/permit-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/permit-payment/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var list, service_objs, pass_included;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  service_objs = ({ [`daily_permit`]: ({ [`servName`]: 'Lower Platte North NRD Daily Permit',[`price`]: 500 }),[`annual_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Resident Permit',[`price`]: 1500 }),[`annual_non_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Non-Resident Permit',[`price`]: 2500 }) });
  pass_included = (getObjectProperty(___arguments.context.pageData, 'pass_type')) == 'nopass' ? false : true;
  list = [];
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'daily_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'daily_permit.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_res_permit.price')))),[`quant`]: 1 }));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_non_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_non_res_permit.price')))),[`quant`]: 1 }));
  } else if ('nopermit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
  }
  addItemToList(list, ({ [`name`]: 'Set Your Sites Fee',[`price`]: Math.min(Math.max(100 * (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))), 100), 800),[`quant`]: 1 }));
  console.log(getObjectProperty(___arguments.context.pageData, 'reg_id'));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/checkout/checkout`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'checkoutItems': list,'reg_id': (getObjectProperty(___arguments.context.pageData, 'reg_id')) })), false, undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/res-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/res-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')),[`lic_plate_num`]: (getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 20 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/res-options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/res-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/select-site-box/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var available_sites, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  available_sites = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/available`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.pageData['available_sites'] = available_sites;
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('7f28da952e22f66c2ac39ee812e15f05', (await asyncListSort((await Promise.all(available_sites.map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'site_name')),[`value`]: (getObjectProperty(item, 'physical_id')) });}))), 1, async (item) => {


   return (getObjectProperty(item, 'label'));
  })));
  console.log(available_sites);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/select-site-cashier/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var available_sites, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if (await Backendless.UserService.isValidLogin()) {
    available_sites = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/available`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    ___arguments.context.pageData['available_sites'] = available_sites;
    (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('7f28da952e22f66c2ac39ee812e15f05', (await asyncListSort((await Promise.all(available_sites.map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'site_name')),[`value`]: (getObjectProperty(item, 'physical_id')) });}))), 1, async (item) => {


     return (getObjectProperty(item, 'label'));
    })));
    console.log(available_sites);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('cashier-log-on', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/site-status/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var registration, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    registration = (await Backendless.Request.post(`${Backendless.appPath}/services/regs/new`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'siteObjectId')))));

  } catch (error) {
    console.log(error);

  }
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('options', ({ [`reg_id`]: (getObjectProperty(registration, 'objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var loaded_site;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  loaded_site = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/check`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pid': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'physical_id'))) }).send());
  if ((getObjectProperty(loaded_site, 'site')) == 'not-found') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('coming-soon', undefined);
  } else {
    ___arguments.context.pageData['site_name'] = (getObjectProperty(loaded_site, 'site.site.site_name'));
    ___arguments.context.pageData['site_avail'] = (getObjectProperty(loaded_site, 'site.available'));
    ___arguments.context.pageData['org_unit_name'] = (getObjectProperty(((getObjectProperty((getObjectProperty((getObjectProperty(loaded_site, 'site')), 'site')), 'org_unit'))[0]), 'org_unit_name'));
    ___arguments.context.pageData['siteObjectId'] = (getObjectProperty(loaded_site, 'site.site.objectId'));
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/site-status/components/b805671e4a1e37767e4841bcce228abf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'site_avail')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/101133bd3c65dcc1199f854b5bf6716e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'site_avail')) ? 'This site is available!' : 'This site is not currently available.')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/site-status/components/401aeab94dd152d9becc08ec23f29858/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/1f941ae64af645ac3fe266c5d84ed395/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'org_unit_name')),' - ',(getObjectProperty(___arguments.context.pageData, 'site_name'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/success/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var site_registration, new_reg_end, reg_start, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  reg_start = (JSON.parse((getObjectProperty(site_registration, 'reg_start'))));
  new_reg_end = (new Date((new Date(reg_start)).getTime() + (((Number((getObjectProperty(site_registration, 'num_nights')))) * 86400) * 1000)));
  new_reg_end.setHours(14);
  new_reg_end.setMinutes(0);
  new_reg_end.setSeconds(0);
  site_registration['reg_end'] = new_reg_end;
  ___arguments.context.pageData['site_reg'] = site_registration;
  try {
    await Backendless.Data.of('site_reg').save(site_registration, true);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/success/components/0465abf544ddda81122fd7ba4dca77f3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Your site is set through ') + String(new Intl.DateTimeFormat('us', ({ [`dateStyle`]: 'full',[`timeStyle`]: 'medium',[`propName`]: 'true' })).format((getObjectProperty(___arguments.context.pageData, 'site_reg.reg_end')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/success/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tent-options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  ___arguments.context.pageData['lic_plate_num'] = '';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/tent-options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) == '') {
    (function (message) { alert(message) })('Please enter your license plate number.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    site_registration['lic_plate_num'] = (getObjectProperty(___arguments.context.pageData, 'lic_plate_num'));
    await Backendless.Data.of('site_reg').save(site_registration, true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('tent-payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')),[`lic_plate_num`]: (getObjectProperty(___arguments.context.pageData, 'lic_plate_num')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 8 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tent-options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tent-options/components/fd8274ba95b86a5ed4181ae72fa176dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lic_plate_num'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tent-payment/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var list, service_objs, pass_included;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  service_objs = ({ [`site`]: ({ [`servName`]: 'WanahooTent Site',[`price`]: 800 }),[`daily_permit`]: ({ [`servName`]: 'Lower Platte North NRD Daily Permit',[`price`]: 500 }),[`annual_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Resident Permit',[`price`]: 1500 }),[`annual_non_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Non-Resident Permit',[`price`]: 2500 }) });
  pass_included = (getObjectProperty(___arguments.context.pageData, 'pass_type')) == 'nopass' ? false : true;
  list = [];
  addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'site.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'site.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'daily_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'daily_permit.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_res_permit.price')))),[`quant`]: 1 }));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_non_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_non_res_permit.price')))),[`quant`]: 1 }));
  } else if ('nopermit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
  }
  addItemToList(list, ({ [`name`]: 'Set Your Sites Fee',[`price`]: Math.min(Math.max(100 * (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))), 100), 800),[`quant`]: 1 }));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/checkout/checkout`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'checkoutItems': list,'reg_id': (getObjectProperty(___arguments.context.pageData, 'reg_id')) })), false, undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/tent-site-status/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var registration, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    registration = (await Backendless.Request.post(`${Backendless.appPath}/services/regs/new`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'siteObjectId')))));

  } catch (error) {
    console.log(error);

  }
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('tent-options', ({ [`reg_id`]: (getObjectProperty(registration, 'objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tent-site-status/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var loaded_site;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  loaded_site = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/check`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pid': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'physical_id'))) }).send());
  if ((getObjectProperty(loaded_site, 'site')) == 'not-found') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('coming-soon', undefined);
  } else {
    ___arguments.context.pageData['site_name'] = (getObjectProperty(loaded_site, 'site.site.site_name'));
    ___arguments.context.pageData['site_avail'] = (getObjectProperty(loaded_site, 'site.available'));
    ___arguments.context.pageData['org_unit_name'] = (getObjectProperty(((getObjectProperty((getObjectProperty((getObjectProperty(loaded_site, 'site')), 'site')), 'org_unit'))[0]), 'org_unit_name'));
    ___arguments.context.pageData['siteObjectId'] = (getObjectProperty(loaded_site, 'site.site.objectId'));
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/tent-site-status/components/b805671e4a1e37767e4841bcce228abf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'site_avail')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tent-site-status/components/101133bd3c65dcc1199f854b5bf6716e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'site_avail')) ? 'This site is available!' : 'This site is not currently available.')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tent-site-status/components/401aeab94dd152d9becc08ec23f29858/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tent-site-status/components/1f941ae64af645ac3fe266c5d84ed395/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'org_unit_name')),' - ',(getObjectProperty(___arguments.context.pageData, 'site_name'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/wifi-cancel/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var error, site_registration;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/wifi-cancel/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/wifi-success/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var error, site_registration, new_reg_end, reg_start;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/wifi-success/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

